import { Container, Grid } from '@mui/material';
import React from 'react';
import toke1 from '../../../asset/tokenomics/token1.png'
import toke2 from '../../../asset/tokenomics/token2.png'
import toke3 from '../../../asset/tokenomics/token3.png'
import toke4 from '../../../asset/tokenomics/token4.png'
import toke5 from '../../../asset/tokenomics/token5.png'
import toke6 from '../../../asset/tokenomics/token6.png'
import './TokenEconomy.css'

const tokenStyle={
    with:'100%'
}
const TokenEconomy = () => {
    return (
        <div>
            <h1 style={{paddingBottom:'40px'}}>Token Economy</h1>
            <Container>
            <Grid container columnSpacing={{ xs:1, md: 4 }} columns={{ xs:2, sm: 8, md: 12 }}>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke1} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}
                    className="token-h2"
                    >
                        <h2>Ovecoin</h2>
                        <p style={{color:'#aaa'}}>The Ovecoin is released in the form of gradual release of inflation, which is mainly </p>
                    </div>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke2} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}
                    className="token-h2"
                    >
                        <h2>Treasury</h2>
                        <p style={{color:'#aaa'}}>Part of which is released to daily tasks, and the other part is injected into City DAOs.</p>
                    </div>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke3} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}
                    className="token-h2"
                    >
                        <h2>Land owning and renting</h2>
                        <p style={{color:'#aaa'}}>Players who hold land NFT assets automatically obtain voting</p>
                    </div>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke4} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}
                    className="token-h2"
                    >
                        <h2>Store operation</h2>
                        <p style={{color:'#aaa'}}>Owning or renting a NFT can open a store in the virtual city.</p>
                    </div>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke5} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}    
                    className="token-h2"
                    >
                        <h2>Staking to Vote</h2>
                        <p style={{color:'#aaa'}}>City players can stake $REAL to get voting rights and get staking rewards at the same time.</p>
                    </div>
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                    <div  style={tokenStyle}>
                    <img 
                    style={{width:'20%'}}
                    src={toke6} alt="" />
                    </div>
                    <div
                    style={{
                        textAlign:'start'
                    }}
                    className="token-h2"
                    >
                        <h2>City events</h2>
                        <p style={{color:'#aaa'}}>The city regularly organizes or co-brands city events. And participating users can get $REAL rewards.</p>
                    </div>
                </Grid>
                
                
               
            </Grid>
                    
            </Container>
        </div>
    );
};

export default TokenEconomy;