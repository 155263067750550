import { Link } from "@mui/material";
import logo from "../../../asset/gold_logo_400.png";

import React from "react";
import "./Navigation.css";
const Navigation = () => {
  return (
    <div style={{ position: "fixed", zIndex: 10, width: "100%" }}>
      <div>
        <nav class="navbar navbar-expand-lg text-white">
          <div class="container-fluid">
            <div></div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon text-white">
                <i class="fa-solid fa-bars"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <div className="lg-manu-left">
                  <li class="nav-item">
                    <div className="social-icon-manu">
                      <button>
                        
                        <a class="nav-link nav-link active text-white" href="https://twitter.com/ovenswapprom" target = "_blank" ><i class="fa-brands fa-twitter "></i></a>
                      </button>
                      
                      
                      <button>
                        <a class="nav-link nav-link active text-white" href="https://t.me/ovencoin" target = "_blank" ><i class="fa-brands fa-telegram "></i></a>
                      </button>
                      
                    </div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link active text-white"
                      aria-current="page"
                      href="#"
                    >
                      Home
                    </a>
                  </li>
                  <hr />
                  <li class="nav-item">
                    <a
                      class="nav-link active text-white"
                      href="https://ovenswap.online/"
                    >
                      Launch Dapp
                    </a>
                  </li>
                  <hr />
                  <li class="nav-item">
                    <a class="nav-link nav-link active text-white" href="#">
                      MarketPlace
                    </a>
                  </li>
                  <hr />
                  
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link active text-white"
                      href="https://ovencoin.fun/world-history-metaverse.pdf"
                      target = "_blank"
                    >
                      WhitePaper
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link active text-white"
                      href="https://ovencoin.fun/kyc.pdf"
                      target = "_blank"
                    >
                      Kyc
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link active text-white"
                      href="https://ovencoin.fun/audit.pdf"
                      target = "_blank"
                    >
                      Audit
                    </a>
                  </li>
                  <li class="nav-item">
                    <button className="app-download app-download-btn-manu">
                      Purchase Ovecoin
                    </button>
                  </li>
                </div>
              </ul>

              <form class="d-flex lg-manu-left ">
                <a class="nav-link text-white" aria-current="page" href="#">
                  Home
                </a>
                <a
                  class="nav-link text-white"
                  aria-current="page" target = "_blank"
                  href="https://ovenswap.online/"
                >
                  Launch Dapp
                </a>
                <a
                  class="nav-link text-white"
                  aria-current="page" target = "_blank"
                  href="https://ovenswap.online/nfts"
                >
                  MarketPlace
                </a>
                 <button className='app-download'><a class="nav-link text-white" aria-current="page" target = "_blank" href="https://ovencoin.fun/world-history-metaverse.pdf">
                 WhitePaper</a></button>
                 <button className='kyc-download'><a class="nav-link text-white" aria-current="page" target = "_blank" href="https://ovencoin.fun/kyc.pdf">
                 Kyc</a></button>
                 <button className='audit-download'><a class="nav-link text-white" aria-current="page" target = "_blank" href="https://ovencoin.fun/audit.pdf">
                 Audit</a></button>      
                
              </form>
            </div>
          </div>
        </nav>
      </div>
      <a class="logo-gold" href="/home">
        <img className="img-fluid logo-gold-style" src={logo} alt="" />
      </a>
      <button className="hover-socail-icon">
        <a class="" href="https://twitter.com/ovenswapprom" target = "_blank"><i class="fa-brands fa-twitter social-ico"></i></a>
        <a class="" href="https://t.me/ovencoin" target = "_blank"><i class="fa-brands fa-telegram social-ico ico-display"></i></a>
        
      </button>
    </div>
  );
};

export default Navigation;
