import React from 'react';
import './Ecosystem.css';
import echo1 from '../../../asset/echosystem/echo1.png'
import echo2 from '../../../asset/echosystem/echo2.png'
import echo3 from '../../../asset/echosystem/echo3.png'
import echo4 from '../../../asset/echosystem/echo4.png'
import echo5 from '../../../asset/echosystem/echo5.png'
import echo6 from '../../../asset/echosystem/echo6.png'
import echo7 from '../../../asset/echosystem/echo7.png'
import echo8 from '../../../asset/echosystem/echo8.png'
import echo9 from '../../../asset/echosystem/echo9.png'
import echo10 from '../../../asset/echosystem/echo10.png';
// partner logo//
import partner1 from '../../../asset/partner/partner1.png'
import partner2 from '../../../asset/partner/partner2.png'
import partner3 from '../../../asset/partner/partner3.png'
import partner4 from '../../../asset/partner/partner4.png'
import partner5 from '../../../asset/partner/partner5.png'
import partner6 from '../../../asset/partner/partner6.png'
import partner7 from '../../../asset/partner/partner7.png'
import partner8 from '../../../asset/partner/partner8.png'
import { Container, Grid } from '@mui/material';
const Ecosystem = () => {
    
    return (
        <div>
             <Container>
            <div className='echo-section'>
                <h1
                style={{textAlign:'start',marginBottom:'30px'}}
                >Ecosystem</h1>
            <Grid container rowSpacing={{ xs: 2, md:6}} 
                  columnSpacing={{ xs: 2, md:2}}
                  columns={{ xs: 4, sm: 8, md: 10 }}>
               
                  
                   <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo1} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo2} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo3} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo4} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo5} alt="" /></div>
                    </Grid>
                   <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo6} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo7} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo8} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo9} alt="" /></div>
                    </Grid>
                    <Grid item xs={2} sm={4} md={2}>
                        <div className='echo-logo'><img src={echo10} alt="" /></div>
                    </Grid>
             </Grid>
            </div>
            <h1
            style={{textAlign:'start',margin:'30px 0'}}
            >Partners</h1>
             <div className='partner-wraper'>
                <img src={partner1} alt="" />
                <img src={partner2} alt="" />
                <img src={partner3} alt="" />
                <img src={partner4} alt="" />
                <img src={partner5} alt="" />
                <img src={partner6} alt="" />
                <img src={partner7} alt="" />
                <img src={partner8} alt="" />
             </div>
             </Container>
        </div>
    );
};

export default Ecosystem;