import './App.css';
import HomeParents from './Components/Home/HomeParents/HomeParents';

function App() {
  return (
    <div className="App">
     <HomeParents></HomeParents>
    </div>
  );
}

export default App;

