import { Backdrop, Box, Fade, Modal } from "@mui/material";
import introVideo from "../../../asset/comp.mp4";
import React from "react";
import "./AboutUs.css";
// import metaIntroImg from '../../../asset/metaIntro.png'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "transfarent",
  boxShadow: 24,
  p: 4,
};

const AboutUs = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div className="about-us">
        <div className="intro-wraper">
          <div className="intro-left">
            {/* <img src={metaIntroImg} alt="" /> */}
            <button onClick={handleOpen}>
              <i class="fa-solid fa-play"></i>
            </button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <video style={{ width: "100%" }} autoPlay controls>
                    <source src={introVideo} type="video/mp4" />
                  </video>
                </Box>
              </Fade>
            </Modal>
          </div>
          <div className="intro-right">
            <header>
              <h1>World History Metaverse</h1>
            </header>
            <p style={{ color: "#bbb" }}>
              The ultimate goal is to create a platform where the world's
              metaverse can participate. Let’s experience every bit of the world
              history. You can see the moments history changed. You are the eye
              witness for the world history.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
