import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./MeetTeam.css";

import shogun from "../../../asset/team-photo/shogun.jpg";
import rin from "../../../asset/team-photo/rin.jpg";
import lee from "../../../asset/team-photo/lee.jpg";
import hana from "../../../asset/team-photo/hana.jpg";
import ryo from "../../../asset/team-photo/ryo.jpg";
import Ic_Linkedin from "../../../asset/team-photo/Ic_Linkedin.png";
import Ic_ttr from "../../../asset/team-photo/Ic_ttr.png";
import Ic_mail from "../../../asset/team-photo/Ic_mail.png";

// import required modules
import { Pagination, Navigation } from "swiper";

const slider = document.querySelector(".items");

const MeetTeam = () => {
  const [num, setNum] = useState(0);

  useEffect(() => {
    setNum(10);
  }, [num]);
  const updateHandler = () => {
    setNum(10);
  };

  window.onload = function () {
    updateHandler(145);
  };
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
  return (
    <div className="meet-team">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="section-title">
              <h1>Meet our team of creative experts.</h1>
              <p>
            
              </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-12">
            <div className="teams-slider">
              <Swiper
                slidesPerView={3}
                spaceBetween={15}
                slidesPerGroup={1}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  "@1.50": {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                }}
                modules={[Pagination, Navigation]}
                // centeredSlides={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="team-block">
                    <div className="team-photo">
                      <img src={shogun} alt="" />
                    </div>
                    <div className="team-content d-flex ">
                      <div className="">
                        <h3>Mr.TANAKA</h3>
                        <p>Team leader</p>
                      </div>
                      <div className="soc-icons">                      
                      
                          <a href="https://twitter.com/Shogun87364165" className="">
                            <img src={Ic_ttr} />
                          </a>
                      
                      </div>
                    
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="team-block">
                    <div className="team-photo">
                      <img src={hana} alt="" />
                    </div>
                    <div className="team-content d-flex ">
                      <div className="">
                        <h3>Hana</h3>
                        <p>Female marketing expert</p>
                      </div>
                       
                      <div className="soc-icons">
                      {/*
                        <a href="#" className="">
                          <img src={Ic_Linkedin} />
                        </a>
                        */}
                        <a href="https://twitter.com/Kunoich98577329" className="">
                          <img src={Ic_ttr} />
                        </a>
                        {/*
                        <a href="#" className="">
                          <img src={Ic_mail} />
                        </a>
                        */}
                      </div>
                      
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="team-block">
                    <div className="team-photo">
                      <img src={lee} alt="" />
                    </div>
                    <div className="team-content d-flex ">
                      <div className="">
                        <h3>Lee</h3>
                        <p>Cryptocurrency expert</p>
                      </div>
                       
                      <div className="soc-icons">
                      
                        <a href="https://twitter.com/Daimyo53463616" className="">
                          <img src={Ic_ttr} />
                        </a>
                        
                      </div>
                    
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="team-block">
                    <div className="team-photo">
                      <img src={ryo} alt="" />
                    </div>
                    <div className="team-content d-flex ">
                      <div className="">
                        <h3>Ryo</h3>
                        <p>Game development expert</p>
                      </div>
                      
                      <div className="soc-icons">
                     
                        <a href="https://twitter.com/Ninja38373630" className="">
                          <img src={Ic_ttr} />
                        </a>
                       
                      </div>
                    
                    </div>
                  </div>
                </SwiperSlide>
                 <SwiperSlide>
                  <div className="team-block">
                    <div className="team-photo">
                      <img src={rin} alt="" />
                    </div>
                    <div className="team-content d-flex ">
                      <div className="">
                        <h3>Rin</h3>
                        <p>3D graphics expert</p>
                      </div>
                    
                      <div className="soc-icons">
                    
                        <a href="https://twitter.com/Shinobi06988422" className="">
                          <img src={Ic_ttr} />
                        </a>
                    
                      </div>
                    
                    </div>
                  </div>
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTeam;
