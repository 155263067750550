import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './RoadMap.css'


const slider = document.querySelector('.items');
const RoadMap = () => {
    const [num,setNum]=useState(0)
  useEffect(()=>{
    setNum(10)
  },[num]);
  const updateHandler = ()=>{
    setNum(10)
   
  }
  
  window.onload = function() {
    updateHandler(145);
    
}
  let isDown = false;
  let startX;
  let scrollLeft;
  if(slider){
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
   
  
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
    return (
        <div className='road-map'>
     
        {/* <main className="grid-item main"> */}
         <Container>
           <h1>Roadmap</h1>
           
         <div className="items">
            <div style={{display:'flex'}} className="line-bar">
            <div className="item item1">
              <div className='event-heading'><h2>Sep.2022</h2></div>
              <div className='card-dot'></div>
              <p>Token issuance</p>
              <p>OveToken will be deployed on BSC mainnet.</p>
            </div>
  
            <div className="item item1">
              <div className='event-heading'><h2>Sep.2022</h2></div>
              <div className='card-dot'></div>
              <p>DEX development start.</p>
              <p>Ovenswap Finance development will start.</p>
            </div>
  
            <div className="item item1">
              <div className='event-heading'><h2>Oct.2022</h2></div>
              <div className='card-dot'></div>
              <p>DEX release.</p>
              <p>Ovenswap Finance development will release.</p>
            </div>
  
            <div className="item item1">
              <div className='event-heading'><h2>Nov.2022</h2></div>
              <div className='card-dot'></div>
              <p>OVE token pre-sale</p>
              <p>OVE Token will puchase by our community investors.</p>
            </div>
  

            <div className="item item1" >
              <div className='event-heading'><h2>Nov.2022</h2></div>
              <div className='card-dot'></div>
              <p>World History metaverse official website production start.</p>
              
            </div> 
            <div className="item item1" >
              <div className='event-heading'><h2>Dec.2022</h2></div>
              <div className='card-dot'></div>
              <p>World History metaverse official website release.</p>
              
            </div> 
            

            <div className="item item1" >
              <div className='event-heading'><h2>Jan.2023</h2></div>
              <div className='card-dot'></div>
              <p>Recruitment of participants and participating companies 
              for the World History Connection with external Metaverse.</p>
              
            </div>
           <div className="item item1" >
              <div className='event-heading'><h2>Feb.2023</h2></div>
              <div className='card-dot'></div>
              <p>Recruitment of participants and participating companies 
              for the World History Connection with external Metaverse.</p>
              
            </div> 
            <div className="item item1" >
              <div className='event-heading'><h2>Mar.2023</h2></div>
              <div className='card-dot'></div>
              <p>OVECoin Presale and Connection with external Metaverse NFT market, start trading (OVE settlement)</p>
            
            </div> 
            <div className="item item1" >
              <div className='event-heading'><h2>Apr.2023</h2></div>
              <div className='card-dot'></div>
              <p>Original NFT market development</p>
            
            </div>
            <div className="item item1" id='last-evebt'>
              <div className='event-heading'><h2>May.2023</h2></div>
              <div className='card-dot'></div>
              <p>Original NFT Market Release and OvenSwap launch</p>
            
            </div>
              </div> 
          </div>

          <div className='footer'>
           <p>&copy; 2023 World History Metaverse</p>
           <p>Info@ovendao.fi</p>

          </div>
              
         </Container>
        {/* </main> */}
      
      </div>
    );
};

export default RoadMap;