import React from 'react';
import './TopBanner.css';
import backgroundVideo from '../../../asset/comp.mp4'
import { Backdrop, Box, Fade, Modal } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:'auto',
    bgcolor: 'transfarent',
    boxShadow: 24,
    p: 4,
  };
const TopBanner = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div  id='home'>
            <video
            className='banner-background'
            autoPlay loop muted
            playsinline="true" disablePictureInPicture="true"
           
           >
            <source src={backgroundVideo} type="video/mp4"/>
            </video>

             <div className='banner-pair-btn'>
                 <button onClick={()=>handleOpen()} className='play-thevideo-btn'>PLAY THE VIDEO</button>
                 <button className='app-download'><a class="nav-link text-white" aria-current="page" target = "_blank" href="https://ovenswap.online/launch">
                 Purchase Ovecoin</a></button>
                            <Modal      
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                open={open}
                                onClose={handleClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                timeout: 500,
                                }}
                            >
                                <Fade in={open}>
                                <Box sx={style}>
                                <video
                                style={{width:'100%'}}
                                    autoPlay
                                    controls
                                >
                                    <source src={backgroundVideo} type="video/mp4"/>
                                    </video>
                                </Box>
                                </Fade>
                            </Modal>
             </div>
        </div>
    );
};

export default TopBanner;