import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Mousewheel } from "swiper";
import "./HomeParents.css";
import Navigation from "../../Shared/Navigation/Navigation";
import TopBanner from "../TopBanner/TopBanner";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import TokenEconomy from "../TokenEconomy/TokenEconomy";
import Ecosystem from "../Ecosystem/Ecosystem";
import Investor from "../Investor/Investor";
import RoadMap from "../RoadMap/RoadMap";
import MeetTeam from "../MeetTeam/MeetTeam";
import Introduction from "../Introduction/Introduction";
const HomeParents = () => {
  return (
    <>
      <Navigation></Navigation>
      <Swiper
        speed={1000}
        direction={"vertical"}
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <TopBanner />
        </SwiperSlide>
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <AboutUs />
        </SwiperSlide>

          <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <Introduction />
        </SwiperSlide>
     
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <TokenEconomy />
        </SwiperSlide>
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <Ecosystem />
        </SwiperSlide>
        {/*
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <Investor />
        </SwiperSlide>
        */}
        /* chirag added for new pages and section start*/
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <MeetTeam />
        </SwiperSlide>
      
        /* chirag end*/
        <SwiperSlide
          style={{ height: "100vh", backgroundColor: "#001", color: "#fff" }}
        >
          <RoadMap />
        </SwiperSlide>

        
      </Swiper>
    </>
  );
};

export default HomeParents;
