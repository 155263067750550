import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Introduction.css";

import Img1 from "../../../asset/intro/img1.png";
import Img2 from "../../../asset/intro/img2.png";
import Img3 from "../../../asset/intro/img3.png";
import Img4 from "../../../asset/intro/img4.png";

// import required modules
import { Pagination, Navigation } from "swiper";

const slider = document.querySelector(".items");

const Introduction = () => {
  const [num, setNum] = useState(0);

  return (
    <div className="Introduction">
      <div className="container">
        <div className="row align-items-center mb-5">
          <div className="section-title">
            <h1 className="w-100 text-center">Features and Advantages</h1>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12 img-shadow">
            <img src={Img1} alt="" />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="section-title">
              <h1>Why do we use it?</h1>
              <p>
                World History Metaverse promotes game incubation and distribution, builds META entertainment metaverse with a variety of games, and creates a new blockchain game financial system, making games financial system, making games not only interesting and fun, but also profitable!.
                <br />
                <br />
                The metaverse is the future way of socializing, earning, shopping and gaming for the digital native generations.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="section-title">
              <h1>History Metaverse?</h1>
              <p>
                World History Metaverse will be an innovative and open metaverse play2earn gaming platform built on the OVE token reward system.
                <br />
                <br />
                Let’s experience every bit of the world history.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 img-shadow">
            <img src={Img2} alt="" />
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Introduction;
